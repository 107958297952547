function removeTrailingSlash(path) {
  if (path.endsWith('/')) {
    return path.slice(0, -1);
  }
  return path;
}

export default function (
  link: string | ((t: (key: string) => string, locale: string) => string)
) {
  const {
    $i18n: { t, locale, locales },
    ssrContext
  } = useNuxtApp();

  const router = useRouter();

  const isIsland = Boolean(ssrContext?.islandContext);

  const resolvedLink =
    typeof link === 'function' ? link(t, locale.value) : link;

  if (!resolvedLink) {
    return { link: '', alternateLink: '' };
  }

  const localeRoute = useLocaleRoute();

  const localePath = useLocalePath();

  const host = useHost();
  const path = usePath();

  const currentRoute = localeRoute(resolvedLink);
  const currentPath = removeTrailingSlash(currentRoute.fullPath);

  const newLink = host + currentPath;

  const shouldNavigateHome = /\/(mx|co|cl|ec|pe)(\/|$)/.test(
    new URL(newLink).pathname
  );

  if (shouldNavigateHome) {
    const homePath = '/';
    if (process.client) {
      history.replaceState(history.state, document.title, homePath);
    } else if (isIsland) {
      router.push(homePath);
    } else {
      navigateTo(homePath, { replace: true, redirectCode: 302 });
    }
  }

  if (currentPath !== path && path !== '/' && !path.startsWith('/?')) {
    // get query params from path
    const [paramlessPath, query] = path.split('?');
    if (paramlessPath !== currentPath) {
      const newPath = currentPath + (query ? `?${query}` : '');
      if (process.client) {
        history.replaceState(history.state, document.title, newPath);
      } else if (isIsland) {
        router.push(newPath);
      } else {
        navigateTo(newPath, { replace: true, redirectCode: 301 });
      }
    }
  }

  function tl(key: string, locale: string) {
    return t(key, null, { locale });
  }

  const hreflangs = {
    us: 'en-US',
    es: 'es-US'
    // mx: 'es-MX',
    // co: 'es-CO',
    // ec: 'es-EC',
    // pe: 'es-PE',
    // cl: 'es-CL'
  };

  let alternateLinks = (
    locales.value.filter(l => l.code in hreflangs) as {
      code: keyof typeof hreflangs;
    }[]
  ).map(alternateLocale => {
    const alternateLocaleCode = alternateLocale.code;
    const tt = (key: string) => tl(key, alternateLocaleCode);
    const alternatePath =
      typeof link === 'function' ? link(tt, alternateLocaleCode) : link;
    const alternateLink = host + localePath(alternatePath, alternateLocaleCode);
    return {
      rel: 'alternate',
      hreflang: hreflangs[alternateLocaleCode],
      link: alternateLink
    };
  });

  const esAlternateLink = alternateLinks.find(
    e => e.hreflang === hreflangs.es
  )?.link;

  if (esAlternateLink) {
    alternateLinks.push({
      rel: 'alternate',
      hreflang: 'x-default',
      link: esAlternateLink
    });
  }

  alternateLinks = alternateLinks.filter(
    l => l.hreflang !== hreflangs[locale.value as keyof typeof hreflangs]
  );

  useHead(() => {
    const shouldNoIndex = /\/(mx|co|cl|ec|pe)(\/|$)/.test(
      new URL(newLink).pathname
    );

    return {
      meta: [
        { hid: 'og:url', property: 'og:url', content: newLink },
        { hid: 'twitter:url', property: 'twitter:url', content: newLink },
        ...(shouldNoIndex
          ? [{ name: 'robots', content: 'noindex', debug: newLink }]
          : [])
      ],
      link: [
        {
          rel: 'canonical',
          href: newLink
        },
        ...alternateLinks
      ]
    };
  });

  return {
    link: newLink,
    alternateLinks: alternateLinks.map(e => e.link)
  };
}
